var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-checkboxes",
    { attrs: { invalid: _vm.error } },
    [
      _c("ck-node-checkboxes", {
        key: _vm.taxonomies[0].id,
        attrs: {
          nodes: _vm.taxonomies,
          checked: _vm.checked,
          disabled: _vm.disabled,
          filteredNodeIds: _vm.filteredTaxonomyIds
        },
        on: {
          update: function($event) {
            return _vm.$emit("update", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _vm.taxonomyCollections[slotProps.node.id]
                  ? _c("gov-hint", { attrs: { for: slotProps.node.id } }, [
                      _vm._v(
                        "Found in\n        " +
                          _vm._s(
                            _vm.taxonomyCollections[slotProps.node.id].join(
                              ", "
                            )
                          )
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }